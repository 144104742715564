<template>
  <entity-table
    v-model:sort-direction="sortDirection"
    v-model:sort-by="sortBy"
    :entities="shifts"
    :loading="loadingShifts"
    :fields="fields"
    :current-page="page"
    :total-items="totalAmount"
    @update:sort="loadShifts"
    @update:page="page = $event; loadShifts()"
  />
</template>

<script>
import { ref } from 'vue';
import axios from '@/services/axios';
import useEntityFields from '@/hooks/api/fields';
import EntityTable from '@/components/shared/EntityTable/EntityTable.vue';

export default {
  name: 'ShiftTable',
  components: { EntityTable },
  props: {
    filterName: { type: String, default: null },
    filterEntity: { type: Object, default: null },
  },
  setup(props) {
    const { shiftShort: fields } = useEntityFields();
    const shifts = ref([]);
    const loadingShifts = ref(false);
    const page = ref(1);
    const totalAmount = ref(0);
    const sortBy = ref('start');
    const sortDirection = ref('desc');

    const loadShifts = () => {
      loadingShifts.value = true;
      const params = {
        page: page.value,

      };
      if (props.filterName) {
        params[props.filterName] = props.filterEntity['@id'];
      }
      params[`order[${sortBy.value}]`] = sortDirection.value;
      axios.get('/shifts', {
        params,
      }).then(({ data: shiftResponse }) => {
        if (shiftResponse['hydra:member']) {
          shifts.value = shiftResponse['hydra:member'];
        }
        if (shiftResponse['hydra:totalItems']) {
          totalAmount.value = shiftResponse['hydra:totalItems'];
        }
      }).catch((err) => {
        console.error(err, err.message, err.response);
      }).finally(() => {
        loadingShifts.value = false;
      });
    };

    loadShifts();

    return {
      fields,
      shifts,
      loadingShifts,
      page,
      totalAmount,
      sortBy,
      sortDirection,
      loadShifts,
    };
  },
};
</script>
