<template>
  <entity-table
    v-model:sort-direction="sortDirection"
    v-model:sort-by="sortBy"
    :entities="requests"
    :loading="loadingRequests"
    :fields="fields"
    :current-page="page"
    :total-items="totalAmount"
    @update:sort="loadRequests"
    @update:page="page = $event; loadRequests()"
  />
</template>

<script>
import { ref } from 'vue';
import { differenceInMilliseconds } from 'date-fns';
import axios from '@/services/axios';
import useEntityFields from '@/hooks/api/fields';
import EntityTable from '@/components/shared/EntityTable/EntityTable.vue';

export default {
  name: 'RequestLogTable',
  components: { EntityTable },
  props: {
    filterName: { type: String, default: null },
    filterEntity: { type: Object, default: null },
  },
  setup(props) {
    const { requestLogList: fields } = useEntityFields();
    const requests = ref([]);
    const loadingRequests = ref(false);
    const page = ref(1);
    const totalAmount = ref(0);
    const sortBy = ref('createdAt');
    const sortDirection = ref('desc');

    const loadRequests = () => {
      loadingRequests.value = true;
      const params = {
        page: page.value,

      };
      if (props.filterName) {
        params[props.filterName] = props.filterEntity['@id'];
      }
      params[`order[${sortBy.value}]`] = sortDirection.value;
      axios.get('/register_request_logs', {
        params,
      }).then(({ data: requestResponse }) => {
        if (requestResponse['hydra:member']) {
          requests.value = requestResponse['hydra:member'];
        }
        if (requestResponse['hydra:totalItems']) {
          totalAmount.value = requestResponse['hydra:totalItems'];
        }
      }).catch((err) => {
        console.error(err, err.message, err.response);
      }).finally(() => {
        loadingRequests.value = false;
      });
    };

    loadRequests();

    return {
      fields,
      requests,
      loadingRequests,
      page,
      totalAmount,
      sortBy,
      sortDirection,
      loadRequests,
      differenceInMilliseconds,
    };
  },
};
</script>
