<template>
  <modal :open="state.open" @close="closeModal">
    <h1>{{ $t('register.onboarding.title') }}</h1>
    <p class="mb-2">
      {{ $t('register.onboarding.warning') }}
    </p>
    <div class="flex flex-col items-end sm:flex-row justify-between sm:items-center mt-8">
      <button class="white" @click="state.open = false; closeModal();">
        {{ $t('general.action.cancel') }}
      </button>
      <button class="red" @click="getOnboardingCode">
        {{ $t('register.onboarding.confirm') }}
      </button>
    </div>
    <div v-if="qrCode">
      <img :src="qrCode" alt="Onboarding Code" style="width: 100%; image-rendering: pixelated;">
    </div>
  </modal>
</template>

<script>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import Modal from '@/components/utils/Modal.vue';
import axios from '@/services/axios';

export default {
  name: 'OnboardRegisterModal',
  components: {
    Modal,
  },
  setup() {
    const store = useStore();
    const state = ref({ open: false });
    const entity = computed(() => store.state.modals.entity);
    watch(() => store.state.modals.onboardRegister?.open, (val) => {
      state.value.open = val;
    });
    const closeModal = () => {
      store.dispatch('modals/closeModal', 'onboardRegister');
    };
    return {
      closeModal,
      state,
      entity,
    };
  },
  data() {
    return {
      baseUrl: window.env.VUE_APP_API_URL || process.env.VUE_APP_API_URL,
      qrCode: null,
    };
  },
  methods: {
    async getOnboardingCode() {
      const { data: code } = await axios.put(`${this.entity['@id']}/onboarding_qr`, {}, {
        headers: {
          'Content-Type': 'application/ld+json',
        },
        responseType: 'arraybuffer',
      });
      this.qrCode = `data:image/png;base64,${Buffer.from(code, 'binary').toString('base64')}`;
    },
  },
};
</script>
